<template>
  <section class="faq">
    
      
     
      
  
         
    <div class="row">
        
 

            <!-- COL1 -->   
             <div class="col-md-12">
                 
                 
                 
                 
               <div class="row" v-show='rldata.is_fin_form'>   
                     
                 <div class="col-12 grid-margin mb-2">
                   <div class="card">
                     <div class="faq-block card-body">
                            <h4 class="mt-2">Спасибо!</h4>
                            <p class="mt-2">Нам важно ваше мнение. </p>
                     </div> 
                   </div> 
                 </div> 
                </div> 
                 
            
                 
                 
                 
               <div class="row" v-show='rldata.is_rating_form' >



                 <div class="col-12 grid-margin mb-2">
                   <div class="card">
                     <div class="faq-block card-body">


                     
                          
                         <h5 class="mt-4 mb-3 ml-0 ">Оцените нас</h5>
                         
                           <p class="mt-4 mb-2 ml-0 ">Текущая заявка</p>   
                           
                      <div class="ml-2 mt-1 mb-2">
                        <star-rating v-bind:increment="1" v-model="rldata.client_raiting_after_ticket" v-bind:max-rating="5" v-bind:star-size="40" v-bind:show-rating="false"></star-rating> 
                      </div>
                           
                           
                          <p class="mt-4 mb-2 ml-0 ">В целом</p> 
                         
                      <div class="ml-2 mt-1 mb-2">
                        <star-rating v-bind:increment="1" v-model="rldata.client_raiting_after_overall" v-bind:max-rating="5" v-bind:star-size="40" v-bind:show-rating="false"></star-rating> 
                      </div>     

                          
                            <div >
                                <b-button  class="btn-danger  ml-0 mb-2 mt-1" v-on:click="update_client_after_raiting" v-bind:disabled="rldata.flags_logic.is_act_disabled">Отправить</b-button>
                           </div>        
                          

                          <p v-if="rldata.flagserror_common.is_error_in_sending_data" class="text-danger">{{ this.$root.error_text.text_error_in_sending_data }}</p>
                          <p v-if="rldata.flagserror_common.is_error_execution_api" class="text-danger" >{{ this.$root.error_text.text_error_execution_api }}</p>


                     </div>
                   </div>
                 </div>























               </div>
             
                 
                 
        
                         
                         
                         
                         
  
           </div>  
         </div>  
        




 
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
       
  
  
  
  
  </section>
</template>

<script>
import $ from 'jquery'
import axios from 'axios';
import axiosRetry from 'axios-retry';   


var data = {
   client_raiting_after_ticket : 5,
   client_raiting_after_overall : 5,
  
  is_rating_form : true,
  is_fin_form : false,
    
    ticknum : '',
   
    flags_logic: {
        is_act_disabled : false,
        is_act2_disabled : false,
    },    
    flagserror_common: {
            is_error_in_sending_data: false,
            is_error_execution_api: false,
    },    
    flagserror_action: {
            //is_error_email_exist: false,
            //text_error_email_exist: "Пользователь с таким email уже зарегистрирован, пожалуйста авторизуйтесь. Если вы забыли свой пароль, проидет процедуру восстановления доступа.  ",
    },
    
    
  
}      
  
import StarRating from 'vue-star-rating';
  
export default {
  name: 'addqr',  
components: {
  StarRating
},

  
    data () { //тюненгуемые переменные и функции
        return {
            rldata: data,  
        };
    },
  
  
  mounted () {
   
   // this.$root.check_version(this.rldata, true);
   
   this.rldata['ticknum']="";
  
       
         //получить данные о пользователе из ls
    var url = new URL(window.location.href);    
    this.rldata['ticknum']=url.searchParams.get("ticknum");  
    
    
       this.rldata['is_rating_form']= true;
       this.rldata['is_fin_form']= false;
 
   
    
   // this.rldata['is_loaded_data']=false; 
    
  },  
  
  
  
  
  
  
methods: {
     
     
     
    
update_client_after_raiting : function ()   //(event) 
    {        
        this.rldata['flagserror_common']['is_error_in_sending_data']=false;
        this.rldata['flagserror_common']['is_error_execution_api']=false;
        
        
      
                this.rldata['flags_logic']['is_act_disabled']=true; //заблокируем кнопку
                
                
                 var url=this.$root.rdata['server_auth'];
                 
                 
             var params = {
                  method:  'common__ask_update_client_after_raiting', 
                   data:  {
                     ticknum : this.rldata['ticknum'], 
                     client_raiting_after_ticket : this.rldata['client_raiting_after_ticket'], 
                     client_raiting_after_overall : this.rldata['client_raiting_after_overall'], 
                  } 
              }; 
       
       
    
      
            axios.post(url, params )    
              .then(response => 
              {   
                   console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                            
                              console.log("READY");
                              
                            this.rldata['is_fin_form']=true; 
                             this.rldata['is_rating_form']=false; 
                            
                        
                                this.rldata['flagserror_common']['is_error_execution_api']=false;
                                //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                               
                           break;
                          
                        
                           
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             this.rldata['flagserror_common']['is_error_execution_api']=true;
                             this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                       
                  //разрешаем кликать на кнопку для дейтсвия 
                   this.rldata['flags_logic']['is_act_disabled']=false; //разаблокируем кнопку
                   
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    this.rldata['flagserror_common']['is_error_in_sending_data']=true;
                    //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                    //разрешаем кликать на кнопку для дейтсвия try_auth
                     this.rldata['flags_logic']['is_act_disabled']=false; //разаблокируем кнопку
                    
                    
            }).then(function () {  });
      
                
                
         
        
        
    },
   
  
  
  
  
  
  
  

  
  
},
  
  
 
   computed: { 
     
       /*         
             check_is_act2_disabled () { 
    //проверяет доступна ли кнопка действия (галка + должно быть завершено предидущее действие кнопки)
     if (this.rldata['ticket_num']!=="" && this.rldata['img_data']!=="" && !this.rldata['flags_logic']['is_act_disabled'] ){
        return false;//true - заблокирована
     }
     return true;
    },    
  
},*/



/*
  watch: {   
      
       "rldata.is_camera_form": {
                handler(value) {

                   
                        if (this.rldata['is_camera_form'])
                            {
                         
                                    
                          
                           }
                        
                    }
                }*/
                        
                        
            },
  
}
  
  
  

</script>


<style scoped>
    .accordion .card .card-header a{
        padding-right: 1.5rem;
    }
    
    .btn_custom_width {
        width: 120px !important;
    }
   
    
    input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}  
  
</style>